@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


@media (min-width: 768px) {
    .education-section {
        @include section-padding-widescreen;
    }
}

.school-logo {
    width: 4em;
    height: 4em;
    clip-path: circle();
    // background-color: $primary-color;
    // padding: .2em;
}

.default-school-logo {
    width: 2.5em;
    height: 2.5em;
    padding: .5em;
    fill: $accent-color;
    background-color: $primary-color;
    clip-path: circle();

}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0em 1em 0em 0em;
    width: 4em;
    height: 4em;
}