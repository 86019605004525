@import '../../assets/styles/variables';


.section-header {
    display: flex;
    align-items: center;    
    padding: 0em 0em 0em 1em;
    @media (min-width: 768px) {
        padding: 1em 1em 1em 4em;
    }
    background-color: $primary-color;
}

.header-title {
    font-size: 1.5em;
    background-color: inherit;
    color: $accent-color;
}

.header-icon {
    width: 2em;
    height: 2em;
    fill: $accent-color;
    margin: 1em;
}