@import '../assets/styles/variables';


.page-container {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    gap: 2em;

}