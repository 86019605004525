@import '../../assets/styles/variables';

.skill-card {
    display: flex;
    flex-direction: column;
    min-height: 20em;
    width: 20em;
    background-color: $primary-color;
    // padding: 0.5em 1em;
    // margin: 0.5em 0;
    border-radius: 1.5em;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.6s ease-in-out, box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        transform: scale(1.05);

        .skill-header .skill-icon {
            animation: flipAnimation 0.6s;
        }
    }

    .skill-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 1em 0;

        h1 {
            font-size: 1.5em;
            color: $accent-color;
            text-align: center;
        }

        .skill-icon {
            width: 3em;
            height: 3em;
            fill: $accent-color;
            transition: transform 0.6s;
        }
    }

    .skill-content {
        flex-grow: 1;
        padding: 1em 2em;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            ::marker {
                content: ">";
                color: $accent-color;
                font-weight: bolder;
            }

            li {
                padding-left: 0.5em;
                transition: color 0.3s;
                cursor: default;



                &:hover {
                    color: $accent-color;
                }
            }
        }
    }
}

// @media (max-width: 768px) {
//     .skill-card {font-size: .9em;}
// }

@media (max-width: 480px) {
    .skill-card {
        font-size: 0.9em;
        width: 100%;
        margin-left: 1em;
        margin-right: 1em;

        .skill-header { 
            .skill-icon {
                width: 2em;
                height: 2em;
            }
        }
    }
}

@keyframes flipAnimation {
    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}