@import '../../assets/styles/variables';

input {
    // padding: 1em;
    border-radius: .5em;
    background-color: $nav-item-hover;
    border: none;
    color: $primary-text;
    font-size: 1em;

    &:focus {
        outline: none;
        border-color: transparent; // Remove border when in focus mode
        // box-shadow: 0 0 0 2px #007bff; // Add a box shadow to indicate focus
        // caret-color: #007bff;
    }

    // &::selection {
    //     background-color: $accent-color; // Set the background color of the selected text
    //     // color: $accent-color; // Set the color of the selected text
    // }
}

input.required label::after {
    content: '*';
    color: red;
}