@import '../../assets/styles/variables';

.degree-card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: wrap;
    background-color: $secondary-color;

    padding: 1em 2em;
    margin: 1em 3em;

    @media (max-width: 768px) {
        font-size: .9em;
        padding: 1em;
        margin: 1em;
    }

    border-radius: 1.5em;

    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
}

.degree-info {
    flex-grow: 1;
}

.degree-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: wrap;

    div {
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        white-space: wrap;
    }
}

.degree-notes {
    font-style: italic;
}

.org-title {
    margin-right: 1em;
    font-size: 1.2em;
}

.degree-title {
    margin-right: 1em;
    font-weight: lighter;
}

.gpa {
    color: $accent-color;
    font-weight: lighter;

    em {
        font-style: normal;
    }
}

.degree-time {
    color: $accent-color;
}