
// .ou-background-image {
//     width: 100%;
//     height: auto;
// }
.space-background-image {
    width: 100%;
    height: 20em;
    object-fit: cover;
}


// body {
//   background: #F0F8EA;
//   font-family: 'Quicksand', sans-serif;
// }

// .container {
//   width: 95%;
//   max-width: 1000px;
//   margin: 0 auto;
// }

// header {
//   background: #E54B4B;
//   color: #EBEBD3;
//   padding: 1em 0;
//   position: relative;

//   &::after {
//     content: '';
//     clear: both;
//     display: block;
//   }
// }



// .site-nav {
//   position: absolute;
//   top: 100%;
//   right: 0%;
//   background: #464655;
//   clip-path: circle(0px at top right);
//   transition: clip-path ease-in-out 700ms;
// }

// .site-nav--open {
//   clip-path: circle(250% at top right);
// }

// .site-nav ul {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }

// .site-nav li {
//   border-bottom: 1px solid #575766;

//   &:last-child {
//     border-bottom: none;
//   }
// }

// .site-nav a {
//   color: #EBEBD3;
//   display: block;
//   padding: 2em 4em 2em 1.5em;
//   text-transform: uppercase;
//   text-decoration: none;

//   &:hover,
//   &:focus {
//     background: #E4B363;
//     color: #464655;
//   }
// }

// .site-nav--icon {
//   display: inline-block;
//   font-size: 1.5em;
//   margin-right: 1em;
//   width: 1.1em;
//   text-align: right;
//   color: rgba(255, 255, 255, 0.4);
// }

// .menu-toggle {
//   padding: 1em;
//   position: absolute;
//   top: 0.5em;
//   right: 0.5em;
//   cursor: pointer;

//   .hamburger,
//   .hamburger::before,
//   .hamburger::after {
//     content: '';
//     display: block;
//     background: #EBEBD3;
//     height: 3px;
//     width: 1.75em;
//     border-radius: 3px;
//     transition: all ease-in-out 500ms;
//   }

//   .hamburger::before {
//     transform: translateY(-6px);
//   }

//   .hamburger::after {
//     transform: translateY(3px);
//   }

//   &.open {
//     .hamburger {
//       transform: rotate(45deg);
//     }

//     .hamburger::before {
//       opacity: 0;
//     }

//     .hamburger::after {
//       transform: translateY(-3px) rotate(-90deg);
//     }
//   }
// }

// @media (min-width: 700px) {
//   .menu-toggle {
//     display: none;
//   }

//   .site-nav {
//     height: auto;
//     position: relative;
//     background: transparent;
//     float: right;
//     clip-path: initial;
//   }

//   .site-nav li {
//     display: inline-block;
//     border: none;
//   }

//   .site-nav a {
//     padding: 0;
//     margin-left: 3em;
//   }

//   .site-nav a:hover,
//   .site-nav a:focus {
//     background: transparent;
//   }

//   .site-nav--icon {
//     display: none;
//   }
// }
