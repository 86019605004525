@import '../../assets/styles/variables';


.introduction-section {
    // background-image: url('../../../public/assets/images/ou.jpg');
    padding: .5em 2em;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.personal-pic {
    align-self: center;
    margin: 1em;
    width: 240px;
    height: 240px;
    clip-path: circle();

    // transform: translate(0%, -10%);
    // filter: grayscale(100%) contrast(120%);
    // box-shadow: 10px 15px 25px 0 rgba(0,0,0,.2);
    transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .1);
        transform: translate(0%, -10%);
    }

    // border: 2px solid #ffffff;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.introduction-content {

    @media (min-width: 768px) {
        padding: .5em 3em .5em 3em;
        max-width: 60%;

    }
    @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
    }


    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.name-title {
    font-family: 'Tangerine', sans-serif;
    color: $primary-text;
    
    font-size: 3em;
    @media (max-width: 480px) {
        font-size: 2em;
    }

    white-space: nowrap;
}

.introduction-paragraph {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: justify;

    @media (max-width: 768px) {
        font-size: .9em;
    }

    // flex-grow: 1;
    // max-width: 60%;
}