@import '../../assets/styles/variables';

.award {
    width: 80%;
    // height: min(20em, auto);
    height: auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    white-space: wrap;
    // background-color: $primary-color;
    background-color: $secondary-color;
    // padding: 0.5em 1em;
    // margin: 0.5em 0;
    border-radius: 1.5em;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.6s ease-in-out, box-shadow 0.3s ease;



    .award-content {
        // flex-grow: 1;
        display: flex;
        flex: 2;
        flex-direction: column;
        padding: 2em 2em;
        gap: .2em;

        .award-title {
            font-size: 1.2em;
            font-weight: bold;
            padding-bottom: .5em;
            padding-top: .5em;
        }

        .award-issuer {
            font-size: .8em;
            display: flex;
            align-items: center;
            gap: 1em;

            .issuer-prefix {
                width: 8em;
            }

            .issuer-logo {
                width: 4em;
                height: 2em;
                object-fit: contain;
            }

            .issuer-anchor {
                color: $accent-color;
            }
        }

        .proof-anchor {
            font-size: 0.9em;
            color: $accent-color;
        }

        .award-affiliate {
            font-size: .8em;
            display: flex;
            align-items: center;
            gap: 1em;

            .affiliate-prefix {
                width: 8em;
            }

            .affiliate-logo {
                width: 4em;
                height: 2em;
                object-fit: contain;
            }

            .affiliate-anchor {
                color: $accent-color;
            }
        }

        .award-description {
            flex-grow: 1;
            padding-top: 1em;
            // padding-bottom: 1em;
            font-size: .8em;

        }


    }

    .award-images {
        flex: 1;
        // background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;

        .award-image {
            object-fit: contain;
            width: 80%;
            height: 80%;
        }
    }

}



@media (max-width: 1024px) {
    .award {
        flex-direction: column;
        height: auto;

        .award-content {
            .award-title {
                font-size: 1em;
            }

            .award-description {
                font-size: .8em;

            }

            .proof-anchor {
            font-size: 0.8em;
            }
        }
    }
}

@media (max-width: 768px) {
    .award {
        .award-content {
            .award-issuer {
                gap: .5em;
                flex-direction: column;
                .issuer-prefix {
                    text-align: center;
                }

            }
            .award-affiliate {
                gap: .5em;
                flex-direction: column;
                .affiliate-prefix {
                    text-align: center;
                }
            }
        }
    }
}