@import '../../assets/styles/mixins';

.courses-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0em 3em 3em 3em;
    gap: .5em;
}

@media (min-width: 768px) {
    .courses-section {
        @include section-padding-widescreen;
    }
}