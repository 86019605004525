@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

header {
    // background-color: $secondary-color;
    padding: 1em;
    display: flex;
    // justify-content: center;
    align-items: center;

    .menu-toggle-btn {
        display: none;
        // margin-top: 1.5em;
        background-color: inherit;
        // padding: 1em 2em;
        // color: $secondary-color;
        // background-color: transparent;
        z-index: 20;

        font-size: 1em;
        cursor: pointer;
        border: none;
        // transition: background-color 0.3s ease, transform 0.3s ease;

        svg {
            background-color: transparent;
            border-radius: .25em;
            width: 2em;
            height: 2em;
            fill: $primary-text;
            transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), background-color .3s ease;
            // transition: transform 0.3s ease;
        }

        &:hover {
            svg {
                fill: $accent-color;
                transform: scale(1.05);
                // background-color: $light-gray;
                // background-color: $primary-text;
            }
        }
    }

    @media (max-width: 768px) {
        justify-content: flex-end;
        .menu-toggle-btn {
            display: block;

            // @include colorWithOpacity($accent-color, .5);
            &:hover {
                svg {
                    fill: $accent-color;
                    transform: scale(1.05);
                    background-color: transparent;
                }
            }
        }
    }

}