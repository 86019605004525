.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-image: url('../../public/assets/images/pexels-free-nature-stock-102539.jpg');
    // padding: 2em;
    // gap: 2em;

    
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;

}