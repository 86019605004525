@import '../assets/styles/variables';

.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.contact-header-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    gap: 1em;

    h1 {
        color: $accent-color;
        font-size: 2em;
    }

    p {
        color: $primary-text;
        text-align: center;
        width: 90%;
    }
}

.contact-handles-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    flex-grow: 1;

    padding: 2em;

    .contact-handles-card {
        padding: 1em;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        width: fit-content;
        border-radius: 1em;
    }
}


@media (max-width: 768px) {
    .page-container {
        padding: .5em;
    }
    
    .contact-header-section {
        h1 {
            color: $accent-color;
            font-size: 1.2em;
        }
    
        p {
            font-size: .9em;
        }
    }
    
}