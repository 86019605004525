@import '../../assets/styles/mixins';

.project-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1em;
}


@media (min-width: 768px) {
    .projects-section {
        @include section-padding-widescreen;
    }

    .project-cards-container {
        margin: 0em 3em 0em 3em;
    }
}