@import '../../assets/styles/variables';

// List items animation variables
$list-item-count: 5; // Number of list items
$animation-duration: 0.3s; // Duration of each animation
$animation-delay: 0.1s; // Delay between each animation

// card theme
$card-content-color: #f6f5f3;
// $card-header-color: #566885;
$card-header-color: #008080;

.course-card {
    display: flex;
    flex-direction: column;
    width: 23em;
    max-height: 12em;
    // background-color: lightgray;
    background-color: $card-content-color;
    overflow: hidden;
    border-radius: 0em 5em 0em 5em;
    transition: max-height 0.3s ease-in-out;
    cursor: pointer;

    .images-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 15%;
        padding-top: .5em;
        padding-bottom: .5em;
        // background-color: gray;
        background-color: $card-header-color;

        .course-image {
            // padding-top: .5em;
            // padding-bottom: .5em;
            // border-top-right-radius: 5em;
            width: 15%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
            // transition: transform 0.4s ease-in-out;
        }

    }

    .course-content {
        flex: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0em 1.5em 2em 1.5em;
        gap: .3em;

        .course-title {
            font-size: .9em;

            &:hover {
                color: $accent-color;
            }
        }

        .course-offeror {
            // display: flex;
            font-size: small;

            .offeror-name {
                &:hover {
                    color: $accent-color;
                }
            }

            .platform-name {
                &:hover {
                    color: $accent-color;
                }
            }
        }

        .num-courses {
            font-size: small;
            font-weight: bold;
            color: $accent-color;
        }

    }


    .course-description {
        max-height: 0em;
        opacity: 0;
        font-size: small;
        transition: max-height .5s ease, opacity .5s ease;
        // line-height: 2em;

        ol li {
            margin-left: 1em;
            // cursor: pointer;
        }
    }

    &:hover {
        max-height: 30em;
        // animation: wiggle 0.4s infinite;
        animation: wiggle .4s;

        .course-description {
            max-height: 20em;
            opacity: 1;

            ol li {
                opacity: 0;
                animation-name: staggered-animation;
                animation-duration: $animation-duration;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in-out;

                @for $i from 1 through $list-item-count {
                    &:nth-child(#{$i}) {
                        animation-delay: ($i - 1) * $animation-delay;
                    }
                }

            }
        }
    }

}

@media (max-width: 480px) {
    .course-card {
        width: 100%;
        margin-left: 1em;
        margin-right: 1em;

        .course-content {
            padding: 0em 1em 2em 1em;
        }

    }
}

@keyframes wiggle {
    0% {
        transform: rotate(-.5deg);
    }

    50% {
        transform: rotate(.5deg);
    }

    100% {
        transform: rotate(-.5deg);
    }
}

@keyframes staggered-animation {
    0% {
        opacity: 0;
        transform: translateX(1em);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}