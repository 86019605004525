@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.nav-items {
    flex: 1;

    ol {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5em;

        .nav-item {
            color: $primary-text;
            padding: 5px 10px;
            border-radius: 5px;
            text-decoration: none;
            transform: scale(1);
            opacity: 1;
            transition: background-color 0.3s ease, transform .3s ease;

            &:hover {
                background-color: $light-gray;
                transform: scale(1.05);
                opacity: .8;
            }

            .nav-anchor {
                text-decoration: none;

                span {
                    transition: transform .3s ease;
                }
            }
        }
    }
}


@media (max-width: 768px) {
    .nav-items {
        display: none;
        position: absolute;
        inset: 0 0 0 50%;
        @include colorWithOpacity($accent-color, .5);
        transition: transform .3s ease-in-out;
        z-index: 10;

        &.visible {
            display: block;
        }

        ol {
            flex-direction: column;
            padding: min(20vh, 10rem) 0em;
            gap: 1em;

            .nav-item {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0em;
                width: 100%;
                height: 3em;
                border-radius: 0em;
                font-weight: bolder;

                .nav-anchor {
                    flex: 1;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &:hover {
                    .nav-anchor {
                        span {
                            color: $primary-color;
                            transform: scale(1.2);
                        }
                    }

                    @include colorWithOpacity($accent-color, .5);
                    transform: scale(1);

                }

                &.active {
                    @include colorWithOpacity($accent-color, .3);
                    opacity: .8;

                    .nav-anchor {
                        span {
                            color: $primary-color;
                        }
                    }
                }

            }
        }
    }
}

//===============================================================
// Slider animation
// .nav-items {
//     flex: 1;
//     animation: navSlideOut 0s none;
//     ol {
//         list-style: none;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         gap: .5em;

//         .nav-item {
//             // display: inline-block;
//             color: $primary-text;
//             padding: 5px 10px;
//             border-radius: 5px;
//             text-decoration: none;
//             transform: scale(1);
//             opacity: 1;
//             transition: background-color 0.3s ease, transform .3s ease;

//             &:hover {
//                 background-color: $light-gray;
//                 transform: scale(1.05);
//                 opacity: .8;
//             }

//             .nav-anchor {
//                 text-decoration: none;

//                 span {
//                     transition: transform .3s ease;
//                 }
//             }
//         }
//     }
// }

// @keyframes navSlide {
//     from {
//         transform: translateX(100%);
//     }

//     to {
//         transform: translateX(0%);
//     }
// }

// @keyframes navSlideOut {
//     from {
//         transform: translateX(0%);
//     }

//     to {
//         transform: translateX(100%);
//     }
// }

// @media (max-width: 768px) {
//     .nav-items {
//         // display: hidden;
//         // transform: translateX(100%);s

//         position: absolute;
//         inset: 0 0 0 50%;
//         // position: absolute;
//         // right: 0;
//         // top: 7vh;
//         // height: 92vh;
//         // width: 50%;
//         transform: translateX(100vw);
//         @include colorWithOpacity($accent-color, .5);
//         // backdrop-filter: blur(.25em);
//         // left: -100%;
//         transition: transform .3s ease-in-out;
//         animation: navSlideOut 0.3s forwards;

//         // transition: visibility 0s 0s, transform .3s ease-in-out;
//         z-index: 10;
//         &.visible {
//             animation: navSlide 0.5s ease both;
//             // display: block;

//         }

//         ol {
//             flex-direction: column;
//             padding: min(20vh, 10rem) 0em;
//             gap: 1em;

//             .nav-item {
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 padding: 0em;
//                 width: 100%;
//                 height: 3em;
//                 border-radius: 0em;
//                 font-weight: bolder;

//                 .nav-anchor {
//                     flex: 1;
//                     height: 100%;
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                 }

//                 &:hover {
//                     .nav-anchor {
//                         span {
//                             color: $primary-color;
//                             transform: scale(1.2);
//                         }
//                     }

//                     @include colorWithOpacity($accent-color, .5);
//                     transform: scale(1);

//                 }
//             }
//         }


//     }
// }
// ==========================