@import '../../assets/styles/variables';

textarea {
    width: 100%;
    padding: 1em;
    border-radius: .5em;
    background-color: $nav-item-hover;
    border: none;
    color: $primary-text;
    font-family: 'Noto Sans', sans-serif;
    color: $primary-text;
    font-size: 1em;
    resize: vertical;

    &:focus {
        outline: none;
        // border-color: transparent;
    }
}