@import '../../assets/styles/variables';

.thank-you-header {
    font-size: 1.5em;
}

.contact-form-section {

    display: flex;
    flex-direction: column;
    border-radius: 1.5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 75%;
    // padding: 2em;
    overflow: hidden;
}

.loading-line {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 4px;
    // top: 50%;
    // background-color: $accent-color;
}

.loading-line::before {
    display: block;
    content: '';
    position: absolute;
    left: -50%;
    width: 30%;
    height: .2em;
    background-color: $accent-color;
    animation: loading-animation 2s linear infinite;
}

@keyframes loading-animation {
    0% {
        left: -50%;
        width: 10%;
        opacity: 1;
    }

    25% {
        opacity: .2;
    }

    50% {
        left: 30%;
        width: 30%;
        opacity: 1;
    }

    75% {
        opacity: .2;
    }

    100% {
        left: 100%;
        width: 10%;
        opacity: 1;
    }
}

.contact-form {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 1em;
    padding: 2em;

    input {
        flex: 1;
        padding: 1em;

    }

    textarea {
        // flex: 2;
        flex-grow: 1;
        // width: 100%;
        padding: 1em;

        min-height: 10em;
    }

    .btn-submit {
        margin-top: 1.5em;
        background-color: $accent-color;
        padding: 1em 2em;
        color: $secondary-color;
        border-radius: 1em;
        font-size: 1em;
        cursor: pointer;
        border: none;
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover:not(:disabled) {
            background-color: lighten($accent-color, 10%);
            transform: scale(1.05);
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
}

@media (max-width: 768px) {
    .contact-form-section {
        width: 100%;
    }

    .contact-form {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-top: 1em;
        padding-bottom: 1em;
        padding-left: .5em;
        padding-right: .5em;

        input {
            width: 70%;
            // margin-left: 1em;
            // padding: 1em;

        }

        textarea {
            // width: 60%;
            flex-grow: 0;
            width: 70%;
        }


    }


}