// Noto Sans
@font-face {
  font-family: 'Noto Sans';
  src: url('../fonts/Noto_Sans/NotoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Optionally, include font weights and styles if necessary
@font-face {
  font-family: 'Noto Sans';
  src: url('../fonts/Noto_Sans/NotoSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../fonts/Noto_Sans/NotoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

// Tangerine
@font-face {
  font-family: 'Tangerine';
  src: url('../fonts/Tangerine/Tangerine-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tangerine';
  src: url('../fonts/Tangerine/Tangerine-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}