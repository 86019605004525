@import '../../assets/styles/mixins';

.slides-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    // margin: 0em 3em 0em 3em;
    gap: 1em;

    iframe {
        width: 357px;
        height: 300px;
        border-radius: 1em;
    }

    @media (max-width: 480px) {
        iframe {
            width: 238px;
            height: 200px;
        }
    }
}

@media (min-width: 768px) {
    .presentations-section {
        @include section-padding-widescreen;
    }
}