@import '../../assets/styles/variables';


.badge {
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 1em;
    // background-color: #e0e0e0;
    // background-color: #e0e0e0;
    border: 1px solid $accent-color;
    padding: .5em;
    gap: .5em;

    .badge-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        color: $accent-color;
    }

    .badge-label {
        font-size: 0.8em;
        font-weight: 500;
        color: $accent-color;
    }
}

// .badge {
//     display: flex;
//     align-items: center;
//     width: fit-content;
//     border-radius: 1em;
//     // background-color: #e0e0e0;
//     // background-color: #e0e0e0;
//     border: 1px solid $accent-color;
//     padding: .5em;
//     gap: .5em;

//     .badge-icon {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         width: 1.5em;
//         height: 1.5em;
//         border-radius: 50%;
//         color: $accent-color;
//     }

//     .badge-label {
//         font-size: 0.9em;
//         font-weight: 500;
//         color: $accent-color;
//     }
// }