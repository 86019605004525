@import '../../assets/styles/variables';

.timeline {
    width: 80%;
    height: auto;
    position: relative;

    .stage {
        width: 50%;
        margin-bottom: 5em;
        // background-color: $secondary-color;
        background-color: $secondary-color;
        border-radius: 1.5em;
        position: relative;
        top: 0;

        .content {
            padding: 1em;

            .time {
                font-weight: bold;
                position: absolute;
                top: -2em;

            }

            .description {
                margin-bottom: 1em;
                line-height: 1.5em;
            }
        }

        .stage-image {
            width: 15em;
            height: 15em;
            object-fit: contain;
            margin-right: .5em;
            margin-bottom: .5em;
        }
    }
}

@media (min-width: 1025px) {
    .timeline {

        // larger screens:
        &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 2px;
            left: 50%;
            transform: translateX(-50%);
            background-color: $accent-color;
        }

        .stage {

            // larger screens:
            &:nth-child(odd) {
                float: right;
                clear: left;
                transform: translateX(1em);
                border-radius: 0em 1.5em 1.5em 1.5em;

                &::after {
                    transform: translate(-50%, -50%);
                    left: -1em;

                    &:hover {
                        background-color: $accent-color;
                    }
                }


            }

            &:nth-child(even) {
                float: left;
                clear: right;
                transform: translateX(-1em);
                border-radius: 1.5em 0em 1.5em 1.5em;

                &::after {
                    transform: translate(50%, -50%);
                    right: -1em;
                }
            }

            &::after {
                content: '';
                position: absolute;
                height: 1.2em;
                width: 1.2em;
                background-color: $accent-color;
                border-radius: 50%;
                top: 0;
            }
        }
    }
}

@media (max-width: 1024px) {
    .timeline {

        // width: 100%;
        .stage {
            font-size: .9em;
            width: 100%;
            margin-bottom: 5em;

            .stage-image {
                width: 10em;
                height: 10em;
                object-fit: contain;
                margin-right: .5em;
                margin-bottom: .5em;
            }
        }
    }
}

@media (max-width: 768px) {
    .timeline {

        // width: 100%;
        .stage {
            .description {
                line-height: 1em;
                font-size: .8em;
            }
        }
    }
}