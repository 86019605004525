@import '../../assets/styles/variables';

// .contact-icons {
//     a {
//         svg {
//             width: 48px;
//             height: 48px;
//             fill: #2B2B2B;            ;
//             transition: color 0.3s ease-in-out;
//             &:hover {
//                 color: #ff00ff;
//                 transform: scale(1.1);
//             }
//         }
//     }
// }

// Fancy rotation
.contact-icons {
    display: flex;
    align-items: center;
    gap: 1em;

    a {
        svg {
            // width: 3em;
            // height: 3em;
            width: 2.5em;
            height: 2.5em;
            fill: $primary-text;
            transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

            &:hover {
                fill: $accent-color;
                transform: scale(1.2) rotate(20deg);
            }
        }
    }
}


// Bubble effect

// .contact-icons {
//     a {
//         svg {
//             width: 48px;
//             height: 48px;
//             fill: #2B2B2B;
//             transition: color 0.3s ease-in-out;

//             &:hover {
//                 color: #ff00ff;
//                 animation: bubble 0.8s ease-in-out infinite;
//                 /* Apply the bubble animation on hover */
//             }
//         }
//     }
// }

// @keyframes bubble {
//     0% {
//         transform: scale(1);
//     }

//     50% {
//         transform: scale(1.2);
//     }

//     100% {
//         transform: scale(1);
//     }
// }