@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


.news-ticker {
    display: flex;
    justify-content: center;
    align-items: center;
    // color: $primary-color;
    color: $accent-color;
    // background-color: $accent-color;
    // background-color: #f8f8f8;
    background-color: #FDF4E9;
    // @include colorWithOpacity($accent-color, .2);
    height: 1.5em;
    gap: 1em;
    // overflow: hidden; 


    // .news-ticker-icon {
    // width: 1.2em;
    // height: 1.2em;
    // }

    .news-ticker-text {
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.9em;
        // span {
        //     display: inline-block;
        //     animation: ticker 15s linear forwards;
        // }
    }

}

@keyframes ticker {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}