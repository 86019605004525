@import '../../assets/styles/variables';

.project-card {
    display: flex;
    flex-direction: column;
    border-radius: 1.5em;
    width: 20em;
    height: 30em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    // box-shadow: 0 2px 4px $accent-color;
    transition: transform 0.6s ease-in-out, box-shadow 0.3s ease;
    overflow: hidden;

    .project-content {
        flex: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        // object-fit: cover;
    }

    .project-image {
        border-top-left-radius: 1.5em;
        border-top-right-radius: 1.5em;
        width: 100%;
        height: 15em;
        object-fit: contain;
        transition: transform 0.4s ease-in-out;
    }

    .project-title {
        // padding-left: .5em;
        // padding-right: .5em;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 1em;
        font-size: 1em;
        transition: color 0.3s ease-out;
        cursor: default;
    }

    .badges-container {
        display: inline-flex;
        flex-wrap: wrap;
        gap: .5em;
        padding: .5em 1em 0em 1em;
        margin-top: .5em;
        cursor: default;
    }

    .project-affiliate {
        width: 2em;
        height: 2em;
        clip-path: circle();
    }

    .description-wrapper {
        flex-grow: 1;
        padding: 1em;
        // width: 18em;
        // display: block;
        // width: 200px; /* Set the desired width for the text wrapper */
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
    }

    .project-description {
        // height: 100%;
        font-size: .9em;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* Set the desired number of lines to show */
        -webkit-box-orient: vertical;
        line-height: 1.2;
        overflow: hidden;
        transition: white-space .3s ease;

        // max-height: 50%;
        // -webkit-line-clamp: 2; /* Set the desired number of lines to show */
        // -webkit-box-orient: vertical;
        // transition: max-height 0.3s ease-in-out;
        // transition: height 0.3s ease-in-out;
        // transition: white-space 0.3s ease-in-out;
        // cursor: default;
    }

    .project-links {
        justify-self: flex-end;
        display: inline-flex;
        padding: 1em;
        gap: 1em;

        a {
            svg {
                width: 2em;
                height: 2em;
                fill: $primary-text;
                transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

                &:hover {
                    fill: $accent-color;
                    transform: scale(1.05) rotate(20deg);
                }
            }
        }

    }


    &:hover {
        .project-title {
            color: $accent-color;
        }

        transform: scale(1.05);

        .project-badge {
            animation: flipAnimation 0.6s ease-in;

            svg {
                font-weight: bold;
            }

            span {
                font-weight: bold;
            }

        }

        .project-image {
            transform: scale(1.05);
            opacity: 20%;
        }

        .project-content {
            transform: translateY(-40%);
        }

        .project-description {
            white-space: normal;
            text-overflow: unset;
            overflow: visible;

        }

    }


}

@media (max-width: 480px) {
    .project-card {
        width: 100%;
        margin-left: 1em;
        margin-right: 1em;
        font-size: 0.9em;
        .project-description {
            font-size: 0.8em;
        }

        .project-links {
            a {
                svg {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
    
        }
    }

}

@keyframes flipAnimation {
    0% {
        transform: rotateX(0deg);
    }

    50% {
        transform: rotateX(180deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}