@import '../../assets/styles/variables';

.alert {
    position: fixed;
    bottom: 2em;
    right: 2em;
    display: flex;
    align-items: center;
    padding: 1em;
    border-radius: .5em;
    font-weight: bold;
    animation: slideIn 0.3s ease-in-out;

    &.alert-info {
        background-color: $color-info;
        color: $color-text-info;
    }

    &.alert-warning {
        background-color: $color-warning;
        color: $color-text-warning;
    }

    &.alert-success {
        background-color: $color-success;
        color: $color-text-success;
    }

    &.alert-error {
        background-color: $color-error;
        color: $color-text-error;
    }

    .alert-icon {
        margin-right: 1em;

        svg {
            width: 2em;
            height: 2em;
        }
    }

    .alert-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: normal;

        .alert-title {
            font-weight: bold;
        }
    }

    @keyframes slideIn {
        from {
            opacity: 0;
            transform: translateY(100%);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}