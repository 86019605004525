@import './variables';
@import './_typography.scss';

* {
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100dvh;
}

body {
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

#root {
    flex: 1;
    // height: 100%; /* Set the height of #root to 100% */
    // overflow: auto; /* Allow scrolling within #root if needed */
    display: flex;
    flex-direction: column;
}

main {
    flex: 1; /* Expand the main element to fill remaining height */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    font-family: 'Noto Sans', sans-serif;
    color: $primary-text;
    font-size: 1em;
}


// @import './variables';
// @import './_typography.scss';

// * {
//     margin: 0;
//     padding: 0;
// }

// html,
// body {
//     height: 100%;
// }

// body {
//     font-family: 'Noto Sans', sans-serif;
//     display: flex;
//     flex-direction: column;
// }

// #root {
//     height: inherit;
// }

// main {
//     // flex: 1;
//     min-height: 100%;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// a {
//     font-family: 'Noto Sans', sans-serif;
//     color: $primary-text;
//     font-size: 1em;
// }