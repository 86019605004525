@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


.skill-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1em;
}


@media (min-width: 768px) {
    .skills-section {
        @include section-padding-widescreen;
    }

    .skill-cards-container {
        margin: 0em 3em 0em 3em;
    }
}