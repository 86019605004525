// Light Theme Colors
$primary-color: #ffffff;
$secondary-color: #F8F9FA;
$accent-color: #841617;

$light-gray: #f0f0f0;
$dark-gray: #323232;

// Dark Theme Colors
// $primary-color-dark: #343a40;
// $secondary-color-dark: #17a2b8;

// typography
$primary-text: #2B2B2B;
$secondary-text: #D4D4D4;

// navigation
$nav-item-hover: #F8F9FA;
// $nav-item-hover: blue;



$color-error: rgb(253, 237, 237);
$color-warning: rgb(255, 244, 229);
$color-success: rgb(237, 247, 237);
$color-info: rgb(229, 246, 253);

$color-text-error: rgb(95, 33, 32);
$color-text-warning: rgb(102, 60, 0);
$color-text-success: rgb(1, 67, 97);
$color-text-info: rgb(30, 70, 32);


// // theme-light.scss
// $background-color: #ffffff;
// $text-color: #2B2B2B;
// // Add other theme-specific variables here

// // theme-dark.scss
// $background-color: #2B2B2B;
// $text-color: #ffffff;
// // Add other theme-specific variables here


// // main.scss
// // Import shared variables and mixins

// // Import light theme
// @import 'theme-light';

// // Import dark theme
// @import 'theme-dark';

// Rest of your SCSS code

// // Primary colors
// $primary-color: #ffffff;     // White
// $primary-color-dark: #f2f2f2; // Slightly darker shade of white
// $primary-color-light: #ffffff; // Slightly lighter shade of white

// // Accent colors
// $accent-color: #841617;    // Dark red
// $accent-color-dark: #5f0f12; // Slightly darker shade of dark red
// $accent-color-light: #b51b21; // Lighter shade of dark red

// // Secondary colors
// $secondary-color: #8c8c8c;      // Gray
// $secondary-color-dark: #565656; // Darker shade of gray
// $secondary-color-light: #bcbcbc; // Lighter shade of gray

// // Text colors
// $primary-text: #2B2B2B;    // Dark gray
// $secondary-text: #8c8c8c;  // Gray
// $accent-text: #ffffff;     // White


