@import '../../assets/styles/variables';

.job-card {
    display: flex;
    // flex-wrap: wrap;
    // white-space: wrap;
    // background-color: $primary-color;
    background-color: $secondary-color;

    padding: 1em;
    margin: 1em;
    
    @media (min-width: 768px) {
        padding: 1em 2em;
        margin: 1em 3em;
    }
    @media (max-width: 767px) {
        font-size: .9em;
    }
    @media (max-width: 480px) {
        font-size: .8em;
        padding: .5em;
    }

    border-radius: 1.5em;

    // shadow effect
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
}

.roles {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.job-info {
    flex-grow: 1;
    // background-color: gray;
}

.job-time {
    color: $accent-color;
    text-decoration: none;
}

.job-employer {
    color: $accent-color;
    // text-decoration: none;
}

.job-location {
    color: $accent-color;
}

.role-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
        display: flex;
        flex-wrap: wrap;
    }
}

.role-title {
    font-size: 1.2em;
}

.job-separator {
    border: none;
    border-top: 1px solid $secondary-text;
    margin: 1em 0;
}

.job-description {
    padding: .5em 2em;
}


.employer-logo {
    // align-self: flex-start;
    // margin: 0em 1em 1em 1em;
    width: 4em;
    height: 4em;
    clip-path: circle();
    object-fit: contain;
    aspect-ratio: 1;
    // border: 2px solid #ffffff;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.default-logo {
    // align-self: flex-start;
    // margin: 0em 1em 1em 1em;
    width: 2.5em;
    height: 2.5em;
    padding: .5em;
    fill: $accent-color;
    background-color: $primary-color;
    clip-path: circle();

}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0em 1em 0em 0em;
    width: 4em;
    height: 4em;
}