@import '../../assets/styles/variables';

footer {
    background-color: $secondary-color;    
    // background-color: $light-gray;    
    // flex: none;
    margin-top: auto;
    // background-color: gray;   
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3em;
}

small {
    color: $accent-color;
    font-size: 1em;
}